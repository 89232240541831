//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2023 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

@import mixins

.controller-homescreen #content-wrapper
  .widget-box
    box-shadow: none
    border: 1px solid var(--color-border-muted)

    &.upsale
      background: $spot-color-feedback-info-light
      flex-basis: 100%
      border-color: $spot-color-feedback-info-dark

.controller-homescreen #breadcrumb
  display: none

.homescreen
  padding-bottom: 0 !important

.homescreen--links
  margin: 10px 0
  display: flex
  padding: 20px 20%
  align-items: center
  justify-content: center

  .icon-context:before
    padding-right: 0

  .homescreen--links--item
    flex: 1
    display: block
    text-align: center
    color: var(--content-icon-link-color)

    i::before
      display: block
      margin-bottom: 10px
      font-size: 3rem
      color: var(--content-icon-link-color)

    &:hover,
    &:hover span
      text-decoration: none
      color: var(--content-link-color)
      i::before
        color: var(--content-link-color)

.homescreen__section-box
  background-color: var(--body-background)
  padding: 33px 25px
  margin-bottom: 34px
  border-radius: 10px

.homescreen__status-container
  display: flex
  justify-content: space-around
  border-radius: 8px
  border: 1px solid var(--gray-lighter)
  padding: 16px 0px
  font-weight: 500
  margin-bottom: 25px

.homescreen__status-item__header
  color: var(--primary-color--minor1)

.headline--application
  color: var(--primary-color)
  margin-bottom: 25px

.homescreen__section-box.calendar
  padding: 0


// .overview
//   .risk-assessment-alert
//     background-color: #CA3F3F
//     color: white
//     padding: 15px
//     width: 100%
//     text-align: center
//     display: flex
//     justify-content: space-around
//     align-items: center
//     position: sticky
//     top: 0/* Sesuaikan ini berdasarkan kebutuhan */
//     left: 0
//     right: 0
//     margin-top: -10px

//     button, .btn-risk-assessment-alert
//       color: #4275FA
//       padding: 10px 20px
//       border-radius: 7px
//       border: none
//       font-size: 14px
//       font-weight: bold
//       background-color: white
//   &__section
//     background-color: var(--body-background)
//     padding: 33px 25px
//     margin-bottom: 34px
//     border-radius: 10px
//     overflow: hidden

//     &__title
//       display: flex
//       justify-content: space-between

//       .title
//         color: #4891FF
//         font-size: 18px
//         margin-bottom: 25px

//         &--project
//           font-size: 24px
//           margin-bottom: 10px
//           display: flex
//           align-items: center
//           gap: 18px

//           .project-status
//             padding: 5px 8px
//             color: white
//             border-radius: 5px
//             background-color: #4891FF
//             font-size: 10px
//             margin: 0

//             &--new-created
//               background-color: #4891FF
//             &--in-progress
//               background-color: #252525
//             &--on-hold
//               background-color: #6F6F6F
//             &--completed
//               background-color: #23BA4E
//             &--closed
//               background-color: #f27c83

//       .project-member
//         display: flex
//         gap: 8px
//         margin-left: auto

//         &__image
//           display: flex


//         &__add-button
//           display: block
//           width: 28px
//           height: 28px
//           display: flex
//           justify-content: center
//           align-items: center
//           border-radius: 50%
//           font-size: 17px
//           border: 1px solid #E5E5E7
//           background: #fff
//           color: #323539
//           text-decoration: none

//     .member-image
//       border-radius: 50%
//       background: gray
//       width: 28px
//       height: 28px
//       display: flex
//       justify-content: center
//       align-items: center
//       font-size: 8px
//       margin-left: -10px
//       color: #fff
//       border: 2px solid #fff

//       &__activity
//         margin-left: 0
    
//     &__link
//       .sharepoint-link
//         display: block /* atau inline-block, tergantung pada layout Anda */
//         width: 100% /* Sesuaikan lebar ini sesuai dengan kontainer induk */
//         overflow: hidden
//         white-space: nowrap
//         text-overflow: ellipsis
    
//     &__input
//       border: 1px solid #cccccc
//       border-radius: 8px

//       form
//         display: flex
//         gap: 10px
//         padding: 8px
        
//       input[type="text"]
//         border: none
      
//       input[type="submit"]
//         border: none
//         color: white
//         background-color: #4891FF
//         border-radius: 8px
//         padding: 0px 10px
    
//     &__table
//       .list__attachment
//         width: 100%
//         border-collapse: collapse

//         thead
//           background-color: #FCFCFD

//         th
//           padding: 12px 24px
//           height: 44px
//           border-bottom: 1px solid #EAECF0
//           text-align: left
//           color: #667085
//           font-size: 12px
//           font-weight: bold

//         tbody
//           tr
//             td
//               padding: 12px 24px
//               height: 44px
//               border-bottom: 1px solid #EAECF0
//               color: #667085
//               font-size: 12px

//               &.name-file
//                 color: #4275FA

//     .activity
//       min-height: 50px
      
//       &__date
//         font-size: 16px
//         padding-bottom: 5px
//         border-bottom: 1px solid #cccccc
      
//       &__item
//         display: flex
//         gap: 5px
//         margin-top: 10px
//         align-items: center
//         margin-bottom: 8px

//         p
//           margin: 0

//         .member-name
//           margin-right: 10px
    
//     .activity__input
//       margin-top: 40px

//     .project-id
//       color: #4891FF
//       font-weight: bold
//       font-size: 16px
    
//     .project-invoice-id
//       color: #23BA4E
//       font-weight: bold
//       font-size: 16px
    
//     .project-description
//       margin-bottom: 25px
//       font-size: 16px
    
//     &__date
//       display: flex
//       gap: 35px
//       font-size: 13px

//       .date-info
//         display: flex
//         gap: 10px

//         &__label
//           font-weight: bold

.add-project-button-container
  display: flex
  justify-content: flex-end

.add-project-button
  background-color: #007bff
  color: white
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
  padding: 17px 10px 23px 10px
  border-radius: 58%
  font-size: 29px
  border: 1px solid #E5E5E7
  text-decoration: none
  text-align: center
  line-height: 0px
  z-index: 1000
  margin-top: -20px

  &:hover
    text-decoration: none

@media screen and (max-width: $breakpoint-sm)
  .homescreen--links
    padding: 20px
    flex-wrap: wrap

    .homescreen--links--item
      flex: auto
      margin: 20px 0
      width: 50%
